/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkmark_complete': {
    width: 8,
    height: 7,
    viewBox: '0 0 8 7',
    data: '<path pid="0" _fill="#1D1D44" d="M1.566 2.167L.509 3.231l2.976 2.956L7.596 1.6 6.48.6 3.422 4.01z"/>'
  }
})
